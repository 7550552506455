.selfIntro {
  position: sticky;
  top: 100rem;
}
.selfIntro .text {
  padding: 50rem 0 110rem;
  display: flex;
}
.selfIntro.loading .textLeft {
  opacity: 0;
  top: 50rem;
  transition: none;
}
.selfIntro.loading .textRight {
  opacity: 0;
  top: 50rem;
  transition: none;
}
.selfIntro .textLeft {
  flex: 0 0 40%;
  padding-left: 50rem;
  font-size: 16rem;
  color: #727272;
  font-weight: 100;
  position: relative;
  top: 0;
  transition: top 0.4s 0.4s, opacity 0.4s 0.4s;
}
.selfIntro .textLeft a {
  color: #727272;
}
.selfIntro .textRight {
  flex: 0 0 60%;
  padding-right: 100rem;
  font-size: 50rem;
  line-height: 1.3em;
  position: relative;
  top: 0;
  transition: top 0.4s 0.5s, opacity 0.4s 0.5s;
}
.selfIntro .seperator {
  /* width: 100%; */
  margin: 0 50rem;
  height: 1px;
  /* background-color: #dedede; */
}

@media screen and (max-width: 767px) {
  .selfIntro {
    top: 60rem;
  }
  .selfIntro .text {
    display: block;
    padding: 30rem 0 45rem;
  }
  .selfIntro .textLeft,
  .selfIntro .textRight {
    width: 100%;
    padding: 0 15rem;
  }
  .selfIntro .textLeft {
    font-size: 16rem;
    margin-bottom: 30rem;
    display: none;
  }
  .selfIntro.loading .textRight {
    top: 30rem;
  }
  .selfIntro .textRight {
    font-size: 25rem;
    line-height: 1.3em;
  }
  .selfIntro .seperator {
    margin: 0 15rem;
    height: 1rem;
  }
}