#about {
  background-color: #FFFFFF;
  position: relative;
  z-index: 1;
}
#about.loading .aboutPhoto {
  opacity: 0;
  padding-top: 250rem;
  transition: none;
}
#about .aboutPhoto {
  position: sticky;
  width: 40%;
  padding: 200rem 100rem 450rem 50rem;
  top: 100rem;
  display: inline-block;
  vertical-align: top;
  opacity: 1;
  /* transform: translateY(0); */
  transition: padding-top 0.4s 0.4s, opacity 0.4s 0.4s;
}
#about .aboutPhoto img {
  width: 100%;
}
#about.loading .aboutText {
  opacity: 0;
  top: 100rem;
  transition: none;
}
#about .aboutText {
  width: 60%;
  display: inline-block;
  vertical-align: top;
  padding-top: 50rem;
  top: 0;
  position: relative;
  transition: top 0.4s 0.5s, opacity 0.4s 0.5s;
  /* height: 3000px; */
}
#about .aboutText .aboutMe {
  /* border-bottom: 1rem solid #EEEEEE; */
}
#about .aboutText .aboutSection:nth-child(last) {
  margin-bottom: 100rem;
}
#about .aboutText .aboutSection:after {
  content: '';
  margin-top: 50rem;
  height: 1rem;
  display: block;
  background-color: #EEEEEE;
  margin-right: 50rem;
  margin-bottom: 60rem;
}


#about .aboutText .mainContent {
  font-size: 45rem;
  line-height: 1.3em;
  padding: 0 100rem 80rem 0;
}
#about .aboutText .subContent {
  padding: 0 150rem 100rem 0;
  font-weight: 100;
}
#about .aboutText .additionCols {
  font-size: 16rem;
}
#about .aboutText .additionCols .colMain {
  width: 40%;
  display: inline-block;
  vertical-align: top;
  line-height: 1.7em;
}
#about .aboutText .additionCols .col {
  width: 60%;
  display: inline-block;
  vertical-align: top;
}
#about .aboutText .additionCols .col .subCol {
  vertical-align: top;
  display: inline-block;
  width: 50%;
}
#about .aboutText .additionCols .col .subCol .colItem {
  margin-bottom: 15rem;
}
#about .aboutText .additionCols .col .subCol .colItem .title {
  font-size: 16rem;
}
#about .aboutText .additionCols .col .subCol .colItem .support {
  font-size: 12rem;
}
#about .aboutText .additionCols .col .subCol .colItem.last {
  font-size: 16rem;
  margin-top: 100rem;
}
#about .aboutText .additionCols .col .colItem a {
  color: #000;
}
#about .aboutText .additionCols .colHeader {
  margin-bottom: 30rem;
  font-style: italic;
}
#about .sideProject {
  padding: 80rem 50rem 50rem;
  position: relative;
  z-index: 1;
}
#about .sideProject .header {
  font-style: italic;
  color: #262527;
  margin-bottom: 30rem;
}
#about .sideProject .projectDetails {
  font-size: 80rem;
}
#about .sideProject .projectDetails a {
  color: #000000;
  text-decoration: none;
}
#about .worksLink {
  margin: 0 0 50rem 50rem;
  text-decoration: none;
  color: #000000;
  display: inline-block;
  font-size: 40rem;
  position: relative;
  z-index: 1;
}
#about .worksLink img {
  width: 30rem;
}
@media screen and (max-width: 767px) {
  #about.loading .aboutPhoto {
    padding-top: 90rem;
  }
  #about.loading .aboutText {
    padding-top: 50rem;
  }
  #about .aboutPhoto {
    position: static;
    padding: 60rem 15rem 0;
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  #about .aboutText {
    width: 100%;
    display: block;
    padding-top: 15rem;
  }
  #about .aboutText .aboutMe {
    /* border-bottom: 1rem solid #EEEEEE; */
  }
  #about .aboutText .aboutWorks {
    margin-bottom: 30rem;
  }
  #about .aboutText .aboutMe:after {
    margin-top: 30rem;
    margin-right: 15rem;
  }
  #about .aboutText .aboutSection:after {
    margin-left: 15rem;
    margin-right: 15rem;
    margin-bottom: 50rem;
  }
  #about .aboutText .mainContent {
    font-size: 25rem;
    line-height: 1.3em;
    padding: 15rem 40rem 15rem 15rem;
  }
  #about .aboutText .subContent {
    font-size: 16rem;
    padding: 15rem 15rem 50rem;
  }
  #about .aboutText .additionCols {
    font-size: 16rem;
  }
  #about .aboutText .mainContent + .additionCols {
    padding-top: 30rem;
  }

  #about .aboutText .additionCols .colMain {
    width: 100%;
    display: block;
    padding: 0 15rem 15rem;
  }
  #about .aboutText .additionCols .col {
    width: 100%;
    padding: 15rem 15rem;
  }
  #about .aboutText .additionCols .col .subCol {
    padding-right: 15rem;
  }
  #about .aboutText .additionCols .col .subCol .colItem {
    margin-bottom: 15rem;
  }
  #about .aboutText .additionCols .col .subCol .colItem .title {
    padding-bottom: 10rem;
  }
  #about .aboutText .additionCols .col .subCol .colItem.last {
    margin-top: 50rem;
  }
  #about .aboutText .additionCols .colHeader {
    margin-bottom: 15rem;
    font-style: italic;
  }
  #about .sideProject {
    padding: 30rem 15rem 30rem;
    position: relative;
    z-index: 1;
  }
  #about .sideProject .header {
    margin-bottom: 20rem;
  }
  #about .sideProject .projectDetails {
    font-size: 50rem;
  }
  #about .worksLink {
    margin: 0 0 50rem 15rem;
    font-size: 25rem;
  }
  #about .worksLink img {
    width: 20rem;
  }
}