#project {
  padding-bottom: 50rem;
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
}
#project.loading .projectName, 
#project.loading .projectCategory, 
#project.loading .projectCoverVimeo, 
#project.loading .projectThumb, 
#project.loading .brief {
  top: 50rem;
  opacity: 0;
  transition: none;
}
#project .projectName {
  font-size: 100rem;
  padding: 150rem 50rem 0rem;
  position: relative;
  top: 0;
  transition: top 0.4s 0.4s, opacity 0.4s 0.4s;
}
#project .projectCategory {
  padding: 0rem 50rem 50rem;
  color: #888888;
  font-weight: 100;
  position: relative;
  top: 0;
  transition: top 0.4s 0.5s, opacity 0.4s 0.5s;
}
#project .projectThumb img {
  vertical-align: top;
  width: 100%;
}
#project .projectThumb {
  position: relative;
  top: 0;
  transition: top 0.4s 0.6s, opacity 0.4s 0.6s;
}
#project .projectCoverVimeo {
  padding-top: 56.25%;
  position: relative;
  top: 0;
  transition: top 0.4s 0.6s, opacity 0.4s 0.6s;
}
#project .projectCoverVimeo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#project .brief {
  padding: 80rem 0;
  position: relative;
  top: 0;
  transition: top 0.4s 0.7s, opacity 0.4s 0.7s;
}
#project .brief .shortInfos {
  display: inline-block;
  vertical-align: top;
  width: 40%;
  padding: 0 50rem;
  font-size: 16rem;
}
#project .brief .shortInfos .infoItem {
  margin-bottom: 15rem;
}
#project .brief .shortInfos .infoItem .header {
  font-style: italic;
  font-weight: lighter;
}
#project .brief .shortInfos .infoItem .content a {
  color: #000000;
}
#project .brief .shortBrief {
  display: inline-block;
  vertical-align: top;
  width: 60%;
  padding: 0 100rem 0 0;
  font-size: 45rem;
  line-height: 1.4em;
}
#project .content.banner,
#project .content.vimeo.fullwidth {
  padding: 0 0 15rem;
}
#project .content.photo {
  padding: 0 50rem 50rem;
}
#project .content.text {
  padding: 20rem 150rem 70rem 40%;
  font-size: 20rem;
}
#project .content.banner + .content.text,
#project .content.vimeo.fullwidth + .content.text {
  padding-top: 55rem;
}
#project .content.vimeo {
  padding: 0 50rem 50rem;
}
#project .content.vimeo .vimeoWrapper {
  position: relative;
  padding-top: 56.25%;
}
#project .content img {
  vertical-align: top;
  width: 100%;
}
#project .content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#project .backToWork {
  text-decoration: none;
  display: inline-block;
  font-size: 40rem;
  color: #000000;
  margin: 50rem;
}
#project .backToWork img {
  width: 20rem;
}
@media screen and (max-width: 767px) {
  #project {
    padding-bottom: 30rem;
  }
  #project.loading .projectName, 
  #project.loading .projectCategory, 
  #project.loading .projectCoverVimeo, 
  #project.loading .projectThumb, 
  #project.loading .brief {
    top: 30rem;
  }
  #project .projectName {
    font-size: 24rem;
    padding: 30rem 15rem 0rem;
  }
  #project .projectCategory {
    padding: 0rem 15rem 15rem;
    font-size: 16rem;
  }
  #project .brief {
    padding: 30rem 0;
    display: flex;
    flex-wrap: wrap;
  }
  #project .brief .shortInfos {
    display: block;
    width: 100%;
    padding: 30rem 15rem 0;
    font-size: 12rem;
    order: 2;
  }
  #project .brief .shortInfos .infoItem {
    display: block;
  }
  #project .brief .shortBrief {
    display: block;
    width: 100%;
    padding: 0 15rem;
    font-size: 20rem;
    order: 1;
  }
  #project .content.photo {
    padding: 0 15rem 15rem;
  }
  #project .content.text {
    padding: 15rem 15rem 30rem;
    font-size: 16rem;
  }
  #project .content.banner + .content.text,
  #project .content.vimeo.fullwidth + .content.text {
    padding-top: 25rem;
  }
  #project .content.vimeo {
    padding: 0 15rem 15rem;
  }

  #project .content.banner,
  #project .content.vimeo.fullwidth {
    padding: 0 0 5rem;
  }
  #project .backToWork {
    font-size: 20rem;
    margin: 15rem 15rem 0;
  }
}