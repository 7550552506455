footer {
  /* padding: 50rem 0 0; */
  position: relative;
  z-index: 9;
  background-color: #FFFFFF;
}

footer .seperator {
  margin: 0 50rem;
  height: 1px;
  background-color: #000000;
}
.slogan {
  padding: 50rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slogan .sloganLeft {
  font-size: 50rem;
  line-height: 1.3em;
}
.slogan .sloganRight {
  font-size: 50rem;
}
footer .contacts {
  padding-left: 40%;
  /* padding-right: 50rem; */
}

footer .contacts .col {
  display: inline-block;
  vertical-align: top;
}
footer .contacts .col:nth-child(1) {
  width: 50%;
}
footer .contacts .col:nth-child(2) {
  width: 45%; /* 30% */
}

footer .contacts .col .colHeader {
  padding-bottom: 50rem;
}
footer .contacts .col .colItem {
  padding: 5rem 0;
}

footer .contacts .col .subCol {
  display: inline-block;
  vertical-align: top;
  width: 45%;
}
footer .contacts .col .colItem a {
  color: #000000;
  text-decoration: none;
}
footer .contacts .col .colItem span {
  font-style: italic;
  color: #0A090A;
  font-size: 16rem;
}
footer .footer {
  display: flex;
  padding: 100rem 0 50rem;
}

footer .footer .me {
  flex: 0 0 40%;
  padding-left: 50rem;
}
footer .footer .copyright {
  flex: 0 0 30%;
}
footer .footer .tnc {
  flex: 0 0 30%;
}
footer .backToTopWrapper {
  position: absolute;
  right: 50rem;
  bottom: 50rem;
  font-size: 40rem;
}
#backToTop {
  position: fixed;
  bottom: 50rem;
  z-index: 1;
  transition: visibility 0.4s, opacity 0.4s;
  text-decoration: none;
  color: #000000;
}
footer .backToTopWrapper img,
#backToTop img {
  width: 30rem;
  vertical-align: bottom;
  margin-bottom: 10rem;
}
footer .backToTopWrapper .backToTopPlaceholder {
  opacity: 0;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  footer .seperator {
    margin: 0 15rem;
  }
  .slogan {
    padding: 30rem 15rem;
  }
  .slogan .sloganLeft {
    font-size: 25rem;
  }
  .slogan .sloganRight {
    font-size: 24rem;
  }
  footer .contacts {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  
  footer .contacts .col {
    display: block;
    margin-bottom: 30rem;
    font-size: 16rem;
  }
  footer .contacts .col:nth-child(1),
  footer .contacts .col:nth-child(2) {
    width: 100%;
  }
  footer .contacts .col .colHeader {
    padding-bottom: 15rem;
  }
  footer .contacts .col .colItem {
    padding: 5rem 0;
    font-size: 16rem;
  }
  footer .contacts .col .subCol {
    width: 50%;
  }
  footer .contacts .col .colItem span {
    font-size: 12rem;
  }
  footer .footer {
    display: block;
    padding: 15rem;
    /* flex-wrap: wrap; */
    font-size: 16rem;
    justify-content: space-between;
  }
  
  footer .footer .me {
    display: none;
    flex: 0 0 100%;
    padding-left: 0;
    font-weight: bold;
    font-size: 20rem;
  }
  footer .footer .copyright {
    display: inline-block;
    margin-right: 10rem;
  }
  footer .footer .tnc {
    display: inline-block;

  }
  footer .backToTopWrapper {
    font-size: 20rem;
  }
  #backToTop {
    right: 15rem;
    bottom: 15rem;
  }
}