#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 10;
  transition: opacity 0s, visibility 0s;
}
#loading.hide {
  transition: opacity 0.3s, visibility 0.3s;
}
#loading .loadingpercent {
  position: absolute;
  right: 50rem;
  left: 50rem;
  bottom: 0;
  text-align: right;
  transition: bottom 0.4s, transform 0.4s;
  font-style: italic;
}
#loading .loadingpercent .digit {
  font-size: 270rem;
  display: block;
}
#loading .loadingpercent .sign {
  font-size: 20rem;
  display: none;
}
/* #landing img {
  width: 20vw;
  vertical-align: top;
} */
@media screen and (max-width: 767px) {
  #loading .loadingpercent {
    position: absolute;
    right: 15rem;
    left: 15rem;
    bottom: 15rem;
    text-align: right;
  }
  #loading .loadingpercent .digit {
    font-size: 140rem;
  }
}