@font-face {
  font-family: GTPlanar;
  src: url('./media/fonts/GT-Planar-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: GTPlanar;
  src: url('./media/fonts/HelveticaNeueLTPro-Bd.otf');
  font-weight: 500;
  font-style: normal;
} */
@font-face {
  font-family: GTPlanar;
  src: url('./media/fonts/GT-Planar-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: GTPlanar;
  src: url('./media/fonts/GT-Planar-Retalic-15-Medium.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: GTPlanar;
  src: url('./media/fonts/GT-Planar-Retalic-15-Regular.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: GTPlanar;
  src: url('./media/fonts/GT-Planar-Retalic-15-Light.otf');
  font-weight: 300;
  font-style: italic;
}
* {
  box-sizing: border-box;
}
html {
  font-size: -webkit-calc(100vw / 1920);
  font-size: -moz-calc(100vw / 1920);
  font-size: calc(100vw / 1920);
}
html, body {
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  margin: 0;
  overflow-x: hidden;
  /* -webkit-overflow-scrolling: auto; */
}
body {
  font-size: 20rem;
  line-height: 1.5;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: GTPlanar, serif;
  font-weight: 400;
  scroll-behavior: smooth;
}
#root {
  /* overflow-x: hidden; */
  width: 100%;
  /* height: 100%; */
  /* -webkit-overflow-scrolling: touch; */
  /* padding-top: 100rem; */
}
.hide {
  opacity: 0;
  visibility: hidden;
}
a:hover {
  color: #606060;
}
@media screen and (max-width: 767px) {
  html {
    font-size: -webkit-calc(100vw / 375);
    font-size: -moz-calc(100vw / 375);
    font-size: calc(100vw / 375);
  }
}