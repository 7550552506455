header {
  position: sticky;
  top: 0rem;
  left: 0;
  width: 100%;
  height: 101rem;
  background-color: #FFFFFF;
  padding: 0 50rem;
  z-index: 0;
  font-size: 20rem;
}
header:before {
  content: "";
  width: 0;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
header .me {
  display: inline-block;
  vertical-align: middle;
}
header .post {
  position: absolute;
  left: 40%;
  top: 50%;
  display: inline-block;
  transform: translateY(-50%);
  font-size: 20rem;
}
header .mainNav {
  position: absolute;
  right: 50rem;
  top: 50%;
  display: inline-block;
  transform: translateY(-50%);
}
header .mainNav a {
  margin-left: 35rem;
  text-decoration: none;
  color: #000000;
  display: inline-block;
}
header .mainNav a:hover {
  /* color: #606060;   */
  font-style: italic;
  color: #000000;
}
header .mobile {
  display: none;
}

header .mobileMenu {
  position: fixed;
  top: 60rem;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s;
}
header .mobileMenu .menuBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}
header .mobileMenu .menuItems {
  position: absolute;
  z-index: 1;
  top: 45%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

header .mobileMenu .menuItems a {
  display: block;
  padding: 5rem 0;
  margin: 0;
  text-decoration: none;
  color: #000000;
  text-align: center;
  font-size: 50rem;
}
header .mobileMenu .menuItems a.active {
  /* font-style: italic; */
}
@media screen and (max-width: 767px) {
  header {
    padding: 0 15rem;
    height: 60rem;
    z-index: 10;
    /* box-shadow: 0 0 10rem #eeeeee; */
  }
  header .me {
    vertical-align: middle;
    font-size: 16rem;
    line-height: 16rem;
    padding-top: 5rem;
  }
  header .post {
    display: none;
    font-size: 12rem;
    position: static;
    transform: none;
    margin-left: 10rem;
    vertical-align: baseline;
  }

  header .mainNav {
    display: none;
  }
  header .mobileMenu.hide {
    transform: translateX(-100%);
  }
  header .mobile {
    position: absolute;
    right: 15rem;
    top: 50%;
    width: 40rem;
    height: 40rem;
    transform: translateY(-50%);
    display: flex;
  }
  header .mobile span {
    position: absolute;
    display: block;
    width: 60%;
    height: 2rem;
    background-color: #000000;
    left: 20%;
    transition: top 0.2s 0.2s, transform 0.2s;
    /* transform: translateZ(0); */
    will-change: transform;
  }
  header .mobile span:nth-child(1) {
    top: 15rem;
  }
  header .mobile span:nth-child(2) {
    top: 23rem;
  }
  header .mobile.opened span {
    transition: top 0.2s, transform 0.2s 0.2s;
  }
  header .mobile.opened span:nth-child(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45DEG);
  }
  header .mobile.opened span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%) rotate(-45DEG);
  }
  header .contactInfo {
    position: absolute;
    bottom: 15rem;
    left: 15rem;
    z-index: 1;
    font-size: 16rem;
  }
  header .contactInfo .colHeader {
    margin-bottom: 15rem;
  }
  header .contactInfo a {
    text-decoration: none;
    color: #000000;
  }
}