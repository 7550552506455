.projectList {
  background-color: #FFFFFF;
  position: relative;
  z-index: 1;
  top: 0;
  transition: top 0.4s 0.6s, opacity 0.4s 0.6s;
  opacity: 1;
}
.projectList.loading {
  top: 50rem;
  opacity: 0;
  transition: none;
}
.projectList .filter {
  padding: 0 50rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  position: relative;
  top: 0rem;
  background-color: #FFFFFF;
  z-index: 1;
}
.projectList .seperator {
  margin: 0 50rem;
  height: 1px;
  background-color: #dedede;
}
.projectList .filter .filterItem {
  flex: 0 0 15%;
  padding: 15rem 0 10rem;
  text-decoration: none;
  color: #000000;
  font-weight: 400;
}
.projectList .filter .filterItem:hover {
  font-style: italic;
  color: #000000;
  /* color: #606060; */
}
.projectList .filter .sortOrder {
  padding: 65rem 0 55rem;
  font-size: 45rem;
  text-decoration: none;
  color: #000000;
  font-weight: 400;
}
.projectList .filter .sortOrder img {
  width: 40rem;
}
.projectList .filter .filterCategories {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 40%;
  right: 50rem;
  font-size: 100rem;
}
.projectList .filter .filterItem.active {
  font-style: italic;
  font-weight: 100;
  /* font-weight: bold; */
}
.projectList .filter .filterCategories .filterItem {
  /* flex: 0 0 33.33333333%; */
  flex: 0 1;
}
.projectsWrapper {
  position: relative;
  overflow: hidden;
  transition: height 0.4s, opacity 0.4s;
}
.projectsWrapper .projectItem {
  position: absolute;
}
.projectsWrapper .projectItem a {
  text-decoration: none;
  color: #000000;
  display: block;
}
.projectsWrapper .projectItem img,
.projectsWrapper .projectItem iframe {
  width: 100%;
  height: 100%;
  vertical-align: top;
  pointer-events: none;
}
.projectsWrapper .projectItem img {
  object-fit: cover;
}
.projectsWrapper .projectItem.featured .projectInfo {
  padding: 15rem 50rem;
}
.projectsWrapper .projectItem .projectInfo {
  padding: 15rem 0;
  /* display: flex; */
  align-items: baseline;
}
.projectsWrapper .projectItem .projectInfo .projectName {
  display: inline-block;
}
.projectsWrapper .projectItem .projectInfo .projectCategory {
  display: inline-block;
  font-weight: 100;
  padding: 0 0 0 15rem;
  color: #888888;
}

.projectsWrapper .projectItem.small .projectInfo .projectName,
.projectsWrapper .projectItem.small .projectInfo .projectCategory {
  display: block;
}
.projectsWrapper .projectItem.small .projectInfo .projectCategory {
  padding: 5rem 0;
}
.mobileFilterCategories {
  display: none;
}

@media screen and (max-width: 767px) {
  .projectList .seperator {
    margin: 0 15rem;
    height: 1rem;
  }
  .projectList .filter {
    padding: 30rem 15rem;
    display: block;
    position: relative;
  }
  .projectList .filter .sortOrder {
    padding: 15rem 0 20rem;
    font-size: 25rem;
  }
  .projectList .filter .sortOrder img {
    width: 15rem;
  }
  .projectList .filter .filterCategories {
    position: static;
    font-size: 50rem;
    display: block;
    left: auto;
    line-height: 1.4em;
  }
  .projectList .filter .filterCategories .filterItem {
    display: block;
    padding: 0;
  }
  .projectsWrapper {
    padding-bottom: 30rem;
  }
  .projectsWrapper .projectItem {
    position: relative;
    margin: 0 auto 15rem;
  }
  .projectsWrapper .projectItem.featured .projectInfo {
    padding: 10rem 0;
  }
  .projectsWrapper .projectItem .projectInfo {
    padding: 10rem 0;
    font-size: 16rem;
  }
  .projectsWrapper .projectItem .projectInfo .projectCategory {
    display: block;
    padding: 0;
  }
  .mobileFilterCategories {
    display: block;
    outline: 0;
    border: 0;
    border-radius: 0;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 16rem;
  }
}